<template>
  <div class="ser-page">
    <div class="ser-tabs">
      <van-tabs v-model="active" >
        <van-tab title="无忧厨改" name="cg" to="/service/cg" replace></van-tab>
        <van-tab title="产品换购" name="hx" to="/service/hx" replace></van-tab>
        <van-tab title="送货" name="sh" to="/service/sh" replace></van-tab>
        <van-tab title="KDS" name="kds" to="/service/kds" replace></van-tab>
        <van-tab title="前置" name="qz" to="/service/qz" replace></van-tab>
        <van-tab title="安装" name="az" to="/service/az" replace></van-tab>
        <van-tab title="维修" name="wx" to="/service/wx" replace></van-tab>
        <van-tab title="保养" name="by" to="/service/by" replace></van-tab>
        <van-tab title="清洗" name="qx" to="/service/qx" replace></van-tab>
        <van-tab title="改气源" name="gqy" to="/service/gqy" replace></van-tab>
      </van-tabs>
    </div>
    <div class="ser-container">
      <router-view />
    </div>

    <!-- 客服浮动按钮 --> 
    <ServiceBtn />
  </div>
</template>

<script>
  import { Tabs, Tab } from 'vant'
  import ServiceBtn from '@/components/service-btn'

  export default {
    components: {
      [Tabs.name]: Tabs,
      [Tab.name]: Tab,
      ServiceBtn
    },
    data() {
      return {
        active: ''
      }
    },
    watch: {
      $route(val) {
        const path = val.path.split('/')
        this.active = path[2]
      }
    },
    created() {
      const path = this.$route.path.split('/')
      this.active = path[2]
    },
  }
</script>

<style lang="less" scoped>
  @import './style.less';
</style>
<style lang="less">
  .ser-tabs {
    .van-tabs__line {
      background-color: #186AFF;
      box-shadow: 0 0.2667vw 0.2667vw rgba(24,106,255,.1);
      width: 5.8667vw;
    }
    .van-tab {
      font-size: 4vw; 
      font-weight: bold;
      color: #9C9DAA;
      padding: 0 4.6667vw;
    }
    .van-tab--active {
      font-size: 4.2667vw;
      color: #1C1F20
    }
  }
</style>